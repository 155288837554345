<template>
  <div class="billingSnap">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Carriers</h1>        
        </div>
      </div>
    </div>
    <div class="container">
      <div class="billingTable" v-if="carriers.length > 0">
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Add New Carrier</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.name" label="Name:"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.sorted" label="Sorted:"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.amazon" label="Amazon:"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.snap" label="Snap:"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.tracking" label="Tracking:"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-card-title>
            Carriers
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="carriers"
            :items-per-page="10"
            :search="search"
            id="data-table">
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        <v-snackbar
        v-model="snackbar"
        :timeout=5000
        >
        {{ text }}
        <v-btn
        color="blue"
        text
        @click="snackbar = false"
        >
        </v-btn>
        </v-snackbar>   
      </div>
    </div>
  </div>
</template>
<script>
import Carriers from '@/services/Carriers.js';

export default {
  data () {
    return {
      // Snackbar
      snackbar: false,
      text: '',
      // Dialog
      dialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        sorted: null,
        amazon: null,
        snap: null,
        tracking: null,
      },
      search: '',
      loading: false,
      carriers: [],
      services: [],
      selectedCarrier: null,
      carrierServices: null,
      valid: false,
      headers: [
        {
          text: 'Carrier Name',
          align: 'left',
          value: 'name',
        },
        { text: 'Sorted Reference', value: 'sorted' },
        { text: 'Amazon Reference', value: 'amazon' },
        { text: 'Snap Reference', value: 'snap' },
        { text: 'Tracking Link', value: 'tracking' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
    }
  },
  created() {
    this.getData(); // NEW - call getData() when the instance is created
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    save () {
      let urlencoded = new URLSearchParams();
      urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);   
      if (this.editedIndex > -1) {
        Object.assign(this.carriers[this.editedIndex], this.editedItem)
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const value = this.editedItem[key];
            urlencoded.append(key, value)
          }
        }
        Carriers.updateCarrierService(urlencoded);
        this.snackbar = true
        this.text = `Successfully Updated ${this.editedItem.name}`
      } else {  
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const value = this.editedItem[key];
            urlencoded.append(key, value)
          }
        }
        this.snackbar = true
        this.text = `Successfully Created ${this.editedItem.name}`
        Carriers.createCarrierService(urlencoded);
        this.carriers.push(this.editedItem)
      }
      this.getData()
      this.close()
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.carriers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true
    },
    deleteItem (item) {      
      const index = this.carriers.indexOf(item)
      if (confirm('Are you sure you want to delete this item?')) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", this.carriers[index].id);
        Carriers.deleteCarrierService(urlencoded);
        this.snackbar = true
        this.text = `Successfully Updated ${this.item.name}`
        this.getData()
      }
    },
    async getData() {
      // Use the BillingSnap to call the getBillingData() method
      Carriers.getCarriers()
      .then(
        (carriers => {
          this.$set(this, "carriers", carriers);
        }).bind(this)
      );
    },
  },
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
  label {
    display: flex;
    align-items: center;
  }
  .button-end {
    align-self: flex-end;
  }
</style>